.EccComicsMenuMobile {
    display: none !important;
}

.EccComicsMenuMobileWrapper .userNav {
    display: none;
}

@media only screen and (max-width:767px) {
    .EccComicsMenuMobile {
        display: block !important;
    }

    .EccComicsMenuMobile .navBarToggler {
        top: 0px !important;
        left: 10px !important;
        position: absolute !important;
    }

    .navBarCollapse {
        position: absolute;
        width: auto;
        left: -92px;
        top: 55px;
    }

    .navBarCollapse ul {
        padding-left: 0;
    }

    .EccComicsMenuMobileWrapper .userNav {
        display: block;
        position: absolute;
        right: 30px;
    }

    .EccComicsMenuMobileWrapper .userNav ul {
        display: flex;
        padding-left: 0;
        gap: 10px;
    }

    .EccComicsMenuMobileWrapper .userNav ul li {
        border: 1px solid #fff;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .EccComicsMenuMobileWrapper .userNav ul li i {
        color: #fff;
        font-size: 16px;
    }
    .stickyMobileHeader .userNav{
        position: fixed;
    }
    .EccComicsMenuMobileWrapper.stickyMobileHeader .userNav ul li {
        border: 1px solid #000;
    }
    .EccComicsMenuMobileWrapper.stickyMobileHeader .userNav ul li i 
    {
        color: #000;
    }

}