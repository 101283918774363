.HeaderEccComics .rightMenuOptions {
    color: #fff;
}

.HeaderEccComics .upperRightMenu {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.HeaderEccComics .upperRightMenuWrapper .menuUl {
    display: flex;
    gap: 10px;
    margin-bottom: 0;
}

.HeaderEccComics .upperRightMenuWrapper .menuUl li {
    padding-bottom: 0px;
}

.HeaderEccComics .socialMediaIconWrapper {
    float: none;
    position: relative;
    padding-bottom: 0;
}

.HeaderEccComics .socialMediaIcon {
    color: #fff;
    font-size: 18px !important;
}

.HeaderEccComics .headerLogo {
    display: flex;
    justify-content: flex-start;
}

.HeaderEccComics .headerLogo img {
    width: 121px;
    height: 49px;
    cursor: pointer;
}

.HeaderEccComics .menuBar {
    padding: 10px;
    width: 75%;
    margin: 30px auto 20px;
}

.HeaderEccComics .menuList {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
}

.HeaderEccComics .menuList .menuItem a {
    color: #fff;
    font-weight: 700;
    font-size: 18px;
}

.HeaderEccComics .searchBar {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #c2c0bd;
    height: 48px;
}

.HeaderEccComics .searchContainer {
    display: flex;
    justify-content: center;
}

.HeaderEccComics .searchButton {
    position: absolute;
    right: 15px;
    top: 8px
}

.HeaderEccComics .searchButton i {
    font-size: 36px;
    color: #fff;
}

.HeaderEccComics .searchwrapper {
    width: 85%;
    position: relative;
}

.HeaderEccComics .userNav .navItem {
    border: 1px solid #fff;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.HeaderEccComics .userNav ul {
    padding-left: 15px;
    display: flex;
    gap: 10px;
}

.HeaderEccComics .userNav .navItem i {
    color: #fff;
    font-size: 30px;
}

.HeaderEccComics.stickyHeader{
    height: auto;
}

.HeaderEccComics.stickyHeader .headerMenuWrapper{
    display: none;
}
.HeaderEccComics .headerLogo.stickyLogo{
   display: none;
}
.stickyHeaderWrapper{
    height: 110px;
}
.stickyHeaderContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 80%;
    margin:20px auto 0;
}
.stickyHeaderContainer .stickyHeaderLogo{
  width: 10%;  
}
.stickyHeaderContainer .stickySearchContainer{
    width: 90%;  
    display: flex;
}
.stickyHeaderContainer .stickySearchContainer .searchBar{
    background-color: transparent;
}
.stickyHeaderContainer .stickySearchContainer .searchButton i
{
    color: #000;
    font-size: 30px;
}
.stickyHeaderContainer .userNav .navItem{
    border: 1px solid #000;
    height: 41px;
    width: 41px;
}
.stickyHeaderContainer .userNav .navItem i{
    color: #000;
}
.stickyHeaderContainer .userNav ul{
    margin-bottom: 0;
}
.stickyHeaderContainer .searchBar{
    height: 41px;
}
.stickyHeaderWrapper .menuList .menuItem a{
    color: #000;
}
.stickyHeaderWrapper .menuList{
    width: 60%;
  margin: 10px auto 0;
}
.HeaderEccComics.EccCommonHeader .rightMenuOptions{
    color: #000;
}

.HeaderEccComics.EccCommonHeader .socialMediaIcon
{
    color: #000;
}

.HeaderEccComics.EccCommonHeader.stickyHeader .upperRightMenu {
    display: flex;
  }

@media only screen and (max-width:767px) {

    .HeaderEccComics .menuList {
        display: none !important;
    }
    .stickyHeader .searchContainer{
        display: none;
    }

    .HeaderEccComics .menuBar {
        background-color: unset;
        margin-top: 10px;
    }

    .HeaderEccComics .headerLogo img {
        width: 102px;
        height: 38px;
    }

    .HeaderEccComics .searchwrapper {
        width: 100%;
    }

    .HeaderEccComics .searchContainer {
        margin-top: 0;
    }

    .HeaderEccComics .menuBar {
        width: 100%;
    }

    .HeaderEccComics .searchBar {
        height: 29px;
        padding: 5px;
        background-color: #a6ccdf;
    }

    .HeaderEccComics .searchButton {
        top: 5px;
        right: 10px;
    }

    .HeaderEccComics .searchButton i {
        font-size: 20px;
    }

    .HeaderEccComics .userNav {
        display: none;
    }
    .HeaderEccComics.stickyHeader .stickyHeaderWrapper
    {
        display: none;
    }
    .HeaderEccComics .headerLogo.stickyLogo{
        display: flex;
     }
     .HeaderEccComics.stickyHeader {
        height: auto;
      }
}