.eccComicsFooter .upperMenu {
    display: flex;
    justify-content: center;
    margin: 15px 0 20px;
}

.eccComicsFooter {
    background-color: #000;
    color: #fff;
    padding-bottom: 30px;
}

.eccComicsFooter .lineBorder {
    border-top: 1px solid #fff !important;
    margin: 0;
}

.eccComicsFooter .lineBorder ul li a {
    color: #fff !important;
    font-weight: 500;
}

.eccComicsFooter .upperMenu ul li a {
    font-size: 16px;
    font-weight: 500;
}

@media only screen and (max-width:767px) {
    .eccComicsFooter .upperMenu ul li a {
        font-size: 8px;
    }

    .eccComicsFooter .upperMenu {
        margin: 10px 0 0;
    }

    .eccComicsFooter {
        padding-bottom: 0;
    }


}